@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap);
body {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}
.imgBackground {
  background: url(/static/media/responsivedesign.09be1e44.jpg) no-repeat center center/cover;
  height: 100vh;
}
.pBackground {
  background: url(/static/media/pricing3.6f42bad1.jpg) no-repeat center center/cover;
  width: 100%;
  height: 40vh;
}
.portBackground {
  background: url(/static/media/portfolio.289cec99.jpg) no-repeat center center/cover;
  width: 100%;
  height: 40vh;
}
.cBackground {
  background: url(/static/media/office.83c68015.jpg) no-repeat center center/cover;
  width: 100%;
  height: 40vh;
}

.contact {
  background: lightskyblue;
  border-radius: 20px;
}

.img-round {
  border-radius: 20px;
}
.navLinks {
  color: #869b9d;
  text-decoration: none;
}
.navLinks:hover {
  color: white;
  text-decoration: none;
  transition: ease-in-out 0.4s;
}

.footerLinks {
  color: white;
  text-decoration: none;
}
.footerLinks:hover {
  color: #869b9d;
  text-decoration: none;
  transition: ease-in-out 0.4s;
}

.unstyled {
  color: white;
  text-decoration: none;
}
.unstyled:hover {
  color: #869b9d;
  text-decoration: none;
  transition: ease-in-out 0.4s;
}
.unstyled2 {
  color: black;
  text-decoration: none;
}
.unstyled2:hover {
  color: white;
  text-decoration: none;
  transition: ease-in-out 0.4s;
}
.PricingTab {
  background: #19a1dd;
  border-radius: 50px;
}
#OnePage {
  background: #7bd0c1;
}
#MultiPage {
  background: #c75b9b;
}
.Bold {
  font-weight: bold;
}
.boxes {
  background: rgb(255, 255, 255, 0.7);
  border: solid 5px black;
  border-radius: 50px;
}
#dollar {
  font-weight: bold;
  font-size: 2.9rem;
}
#dollar2 {
  font-weight: bold;
  font-size: 2.9rem;
  padding: 15px;
}
.Contact {
  background: #212529;
  border-radius: 20px;
  padding: 15px;
}
/* text stroke */
.strokeBlack {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}

/* Tiles */

#tiles {
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

#tiles article > .image {
  transition: transform 0.5s ease;
  position: relative;
  display: block;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
}

#tiles article > .image img {
  display: block;
  width: 100%;
}

#tiles article > .image:before {
  pointer-events: none;
  transition: background-color 0.5s ease, opacity 0.5s ease;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 1;
  opacity: 0.8;
}

#tiles article > .image:after {
  pointer-events: none;
  transition: opacity 0.5s ease;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cstyle%3Eline %7B stroke-width: 0.25px%3B stroke: %23ffffff%3B %7D%3C/style%3E%3Cline x1='0' y1='0' x2='100' y2='100' /%3E%3Cline x1='100' y1='0' x2='0' y2='100' /%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  opacity: 0.25;
  z-index: 2;
}

#tiles article > a {
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-direction: column;
  flex-direction: column;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  transition: background-color 0.5s ease, transform 0.5s ease;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1em;
  border-radius: 4px;
  border-bottom: 0;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  z-index: 3;
}

#tiles article > a > :last-child {
  margin: 0;
}

#tiles article > a:hover {
  color: #ffffff !important;
}

#tiles article > a h2 {
  margin: 0;
}

#tiles article > a .content {
  transition: max-height 0.5s ease, opacity 0.5s ease;
  width: 100%;
  max-height: 0;
  line-height: 1.5;
  opacity: 0;
}

#tiles article > a .content > :last-child {
  margin-bottom: 0;
}

#tiles article.style1 > .image:before {
  background-color: #f2849e;
}

#tiles article.style2 > .image:before {
  background-color: #7ecaf6;
}

#tiles article.style3 > .image:before {
  background-color: #7bd0c1;
}

#tiles article.style4 > .image:before {
  background-color: #c75b9b;
}

#tiles article.style5 > .image:before {
  background-color: #ae85ca;
}

#tiles article.style6 > .image:before {
  background-color: #8499e7;
}

body:not(.is-touch) #tiles article:hover > .image {
  transform: scale(1.1);
}

body:not(.is-touch) #tiles article:hover > .image:before {
  background-color: #333333;
  opacity: 0.35;
}

body:not(.is-touch) #tiles article:hover > .image:after {
  opacity: 0;
}

body:not(.is-touch) #tiles article:hover .content {
  max-height: 15em;
  opacity: 1;
}

body.is-preload #tiles article {
  transform: scale(0.9);
  opacity: 0;
}

body.is-touch #tiles article .content {
  max-height: 15em;
  opacity: 1;
}

