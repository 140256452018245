@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap");
body {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}
.imgBackground {
  background: url("./responsivedesign.jpg") no-repeat center center/cover;
  height: 100vh;
}
.pBackground {
  background: url("./pricing3.jpg") no-repeat center center/cover;
  width: 100%;
  height: 40vh;
}
.portBackground {
  background: url("./portfolio.jpg") no-repeat center center/cover;
  width: 100%;
  height: 40vh;
}
.cBackground {
  background: url("./office.jpg") no-repeat center center/cover;
  width: 100%;
  height: 40vh;
}

.contact {
  background: lightskyblue;
  border-radius: 20px;
}

.img-round {
  border-radius: 20px;
}
.navLinks {
  color: #869b9d;
  text-decoration: none;
}
.navLinks:hover {
  color: white;
  text-decoration: none;
  transition: ease-in-out 0.4s;
}

.footerLinks {
  color: white;
  text-decoration: none;
}
.footerLinks:hover {
  color: #869b9d;
  text-decoration: none;
  transition: ease-in-out 0.4s;
}

.unstyled {
  color: white;
  text-decoration: none;
}
.unstyled:hover {
  color: #869b9d;
  text-decoration: none;
  transition: ease-in-out 0.4s;
}
.unstyled2 {
  color: black;
  text-decoration: none;
}
.unstyled2:hover {
  color: white;
  text-decoration: none;
  transition: ease-in-out 0.4s;
}
.PricingTab {
  background: #19a1dd;
  border-radius: 50px;
}
#OnePage {
  background: #7bd0c1;
}
#MultiPage {
  background: #c75b9b;
}
.Bold {
  font-weight: bold;
}
.boxes {
  background: rgb(255, 255, 255, 0.7);
  border: solid 5px black;
  border-radius: 50px;
}
#dollar {
  font-weight: bold;
  font-size: 2.9rem;
}
#dollar2 {
  font-weight: bold;
  font-size: 2.9rem;
  padding: 15px;
}
.Contact {
  background: #212529;
  border-radius: 20px;
  padding: 15px;
}
/* text stroke */
.strokeBlack {
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}
